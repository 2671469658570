import request from "@/utils/http/requset";

function getRefundOrderList(data) {
  return request({
    url: "/manage/recharge/search",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export default {
  getRefundOrderList,
};
