<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="商户">
        <el-select clearable v-model="searchForm.merchantId">
          <el-option
            v-for="item in merchantList"
            :key="item.id"
            :label="item.merchantName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="商户单号">
        <el-input v-model="searchForm.orderNo" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="支付单号">
        <el-input v-model="searchForm.tradeNo" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input
          v-model="searchForm.cellphone"
          size="mini"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="交易状态">
        <el-select v-model="searchForm.status" size="mini" clearable>
          <el-option
            v-for="item in statusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="searchForm.startTime"
          type="date"
          value-format="yyyy-MM-dd"
          size="mini"
          clearable
        ></el-date-picker
        >--
        <el-date-picker
          v-model="searchForm.endTime"
          type="date"
          value-format="yyyy-MM-dd"
          size="mini"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" type="primary" size="mini">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column
        prop="no"
        label="序号"
        width="50"
        type="index"
      ></el-table-column>
      <el-table-column prop="merchantName" label="商户名称"></el-table-column>
      <el-table-column prop="username" label="用户姓名"></el-table-column>
      <el-table-column prop="cellphone" label="手机号"></el-table-column>
      <el-table-column prop="status" label="交易状态">
        <template slot-scope="scope">
          <span>{{ getOrderStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="accountName" label="渠道名称"></el-table-column>
      <el-table-column prop="accountId" label="账号id"></el-table-column>
      <el-table-column prop="orderNo" label="商户单号"></el-table-column>
      <el-table-column prop="tradeNo" label="支付单号"></el-table-column>
      <el-table-column prop="utr" label="UTR"></el-table-column>
      <el-table-column prop="amount" label="交易金额">
        <template slot-scope="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="fee" label="手续费">
        <template slot-scope="scope">
          <span>{{ scope.row.fee / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="请求时间">
        <template slot-scope="scope">
          <span>{{ dateTransform(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealTime" label="处理时间">
        <template slot-scope="scope">
          <span>{{ dateTransform(scope.row.dealTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="notifyStatus" label="回调状态">
        <template slot-scope="scope">
          <span v-if="scope.row.notifyStatus == 0">未回调</span>
          <span v-if="scope.row.notifyStatus == 1">已回调</span>
        </template>
      </el-table-column>
      <el-table-column prop="notifyTimes" label="回调次数"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
  </section>
</template>

<script>
import api from "../../api/orderManage/refundOrder";
import merchantApi from "@/api/merchantPayConfig/merchantApi";
import DataUtil from "../../utils/config/dataUtil";
import Enum from "../../utils/Enum/Enum";
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,
      loading: false,
      tableData: [],
      statusList: Enum.orderStatus,
      searchForm: {
        merchantId: "",
        transactionId: "",
        tradeNo: "",
        username: "",
        cellphone: "",
        status: "",
        startTime: this.date4Query(new Date()),
        endTime: this.date4Query(new Date()),
      },
      merchantList: [],
    };
  },
  methods: {
    merchantSelect() {
      merchantApi.getMerchant({}).then((res) => {
        if (res.code == "0000") {
          this.merchantList = res.data.rows;
        }
      });
    },
    dateTransform(date) {
      return DataUtil.dateTransform(date);
    },
    date4Query(date) {
      return DataUtil.dateTransformYMD(date);
    },
    getOrderStatus(val) {
      return Enum.getOrderStatus(val);
    },
    search() {
      this.pageIndex = 1;
      this.bindGrid();
    },
    bindGrid() {
      this.loading = true;
      if (this.searchForm.startTime == null) {
        this.searchForm.startTime = "";
      }
      if (this.searchForm.endTime == null) {
        this.searchForm.endTime = "";
      }
      let _data = {};
      let content = Object.assign({}, this.searchForm);
      content.pageNo = this.pageIndex;
      content.pageSize = this.pageSize;
      _data.content = content;
      api.getRefundOrderList(_data).then((res) => {
        this.logining = false;
        if (res.code == "0000") {
          this.loading = false;
          this.tableData = res.data.rows;
          this.dataTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    },
  },
  mounted: function () {
    this.bindGrid();
    this.merchantSelect();
  },
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/base";
.el-form-item {
  margin-bottom: 10px;
}
</style>
